var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('period-selector',{on:{"period-changed":function($event){_vm.dates = $event; _vm.updateFlights();}}}),_c('v-card',[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.metar)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.taf)+" ")])],1),_c('v-subheader',[_vm._v("Departures")]),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.departingFlights,"items-per-page":10,"item-class":_vm.rowClassesDepartures,"sort-by":"eobt","show-group-by":"","dense":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.eobt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.eobt.toNiceUtc())+" ")]}},{key:"item.estimatedArrivalTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.estimatedArrivalTime.toNiceUtc())+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state === 3)?_c('v-chip',{attrs:{"color":"green","x-small":""}},[_vm._v("Active ")]):_vm._e()]}}],null,true)}),_c('v-subheader',[_vm._v("Arrivals")]),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.arrivingFlights,"items-per-page":10,"item-class":_vm.rowClassesArrivals,"sort-by":"estimatedArrivalTime","show-group-by":"","dense":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.eobt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.eobt.toNiceUtc())+" ")]}},{key:"item.estimatedArrivalTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.estimatedArrivalTime.toNiceUtc())+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.state === 3)?_c('v-chip',{attrs:{"color":"green","x-small":""}},[_vm._v("Active ")]):_vm._e()]}}],null,true)}),_c('v-card',[_c('v-card-title',[_vm._v("Legend")]),_c('v-card-text',[_c('v-chip',{attrs:{"color":"rgba(144, 238, 144, 0.342)"}},[_vm._v("Local")]),_c('v-chip',{attrs:{"color":"rgba(238, 144, 144, 0.541)"}},[_vm._v("Cancelled")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }