<template>
  <v-data-table
    :headers="headers"
    :items="flights"
    :items-per-page="10"
    :item-class="rowClasses"
    :custom-sort="customSort"
    sort-by="eobt"
    @click:row="handleClick"
    show-group-by
    dense
    class="elevation-2 row-pointer"
  >
    <template v-slot:top>
      <period-selector @period-changed="dates = $event; updateFlights();" />
    </template>
    <template v-slot:[`item.eobt`]="{ item }">
      {{ item.eobt.toNiceUtc() }}
    </template>
    <template v-slot:[`item.state`]="{ item }">
      <v-chip
        v-if="item.state === 3"
        color="green"
      >
        <v-icon>mdi-airplane</v-icon>
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PeriodSelector from './Shared/PeriodSelector.vue'
export default {
  components: { PeriodSelector },
  data: () => ({
    headers: [
      {
        text: 'EOBT',
        value: 'eobt',
        groupable: false,
      },
      { text: 'Origin', value: 'origin' },
      { text: 'Destination', value: 'destination' },
      { text: '', value: 'state' },
    ],
    dates: [],
  }),
  computed: {
    ...mapState('aircraft', ['flights']),
  },
  created() {
    this.$store.dispatch('aircraft/getFlights', {
      aircraftId: this.$route.params.aircraftId,
    })
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'eobt') {
          const aDate = new Date(a.eobt)
          const bDate = new Date(b.eobt)

          if (isDesc[0] == false) {
            return aDate < bDate ? -1 : 1
          } else {
            return bDate < aDate ? -1 : 1
          }
        } else {
          if (isDesc[0] == false) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    rowClasses(item) {
      if (item.lastEvent == 'CNL' || item.state == 1) {
        return 'cancelled'
      }
      if (item.origin == item.destination) {
        return 'local' //can also return multiple classes e.g ["orange","disabled"]
      }
      return ''
    },
    handleClick: function (row) {
      this.$router.push({
        name: 'Flight',
        params: { flightId: row.flightId },
      })
    },
    updateFlights: function (event) {
      this.$store.dispatch('aircraft/getFlights', {
        aircraftId: this.$route.params.aircraftId,
        startDate: this.dates[0],
        endDate: this.dates[1],
      })
    },
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> .local {
  background-color: rgba(144, 238, 144, 0.342);
}
.row-pointer >>> .cancelled {
  background-color: rgba(238, 144, 144, 0.541);
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>