<template>
  <v-menu
    ref="menu"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">

      <v-text-field
        v-model="dateRangeText"
        label="Date range"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="dates"
      range
      full-width
      :landscape="$vuetify.breakpoint.smAndUp"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(dates);$emit('period-changed', dates)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    dates: [],
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
}
</script>

<style>
</style>