<template>
  <v-container>

    <period-selector @period-changed="dates = $event; updateFlights();" />

    <v-card>
      <v-card-text>
        {{ metar }} <br /> {{ taf }}
      </v-card-text>
    </v-card>
    <v-subheader>Departures</v-subheader>
    <v-data-table
      :headers="
      headers"
      :items="departingFlights"
      :items-per-page="10"
      :item-class="rowClassesDepartures"
      sort-by="eobt"
      @click:row="handleClick"
      show-group-by
      dense
      class="elevation-2 row-pointer"
    >
      <template v-slot:[`item.eobt`]="{ item }">
        {{ item.eobt.toNiceUtc() }}
      </template>
      <template v-slot:[`item.estimatedArrivalTime`]="{ item }">
        {{ item.estimatedArrivalTime.toNiceUtc() }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-chip
          v-if="item.state === 3"
          color="green"
          x-small
        >Active
        </v-chip>
      </template>
    </v-data-table>
    <v-subheader>Arrivals</v-subheader>
    <v-data-table
      :headers="headers"
      :items="arrivingFlights"
      :items-per-page="10"
      :item-class="rowClassesArrivals"
      sort-by="estimatedArrivalTime"
      @click:row="handleClick"
      show-group-by
      dense
      class="elevation-2 row-pointer"
    >
      <template v-slot:[`item.eobt`]="{ item }">
        {{ item.eobt.toNiceUtc() }}
      </template>
      <template v-slot:[`item.estimatedArrivalTime`]="{ item }">
        {{ item.estimatedArrivalTime.toNiceUtc() }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-chip
          v-if="item.state === 3"
          color="green"
          x-small
        >Active
        </v-chip>
      </template>
    </v-data-table>
    <v-card>
      <v-card-title>Legend</v-card-title>
      <v-card-text>
        <v-chip color="rgba(144, 238, 144, 0.342)">Local</v-chip>
        <v-chip color="rgba(238, 144, 144, 0.541)">Cancelled</v-chip>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PeriodSelector from '../Shared/PeriodSelector.vue'
export default {
  components: { PeriodSelector },
  data: () => ({
    headers: [
      {
        text: 'EOBT',
        value: 'eobt',
        groupable: false,
        sort: (a, b) => {
          return (a > b) - (a < b)
        },
      },
      {
        text: 'Arrival time',
        value: 'estimatedArrivalTime',
        groupable: false,
        sort: (a, b) => {
          return (a > b) - (a < b)
        },
      },
      { text: 'Registration', value: 'registration' },
      { text: 'Callsign', value: 'callsign' },
      { text: 'Type', value: 'aircraftType' },
      { text: 'Origin', value: 'origin' },
      { text: 'Destination', value: 'destination' },
      { text: 'State', value: 'state' },
    ],
    dates: [],
  }),
  computed: {
    ...mapState('stations', ['flights', 'metar', 'taf']),
    departingFlights: function () {
      return this.flights.filter((f) => f.origin == this.$route.params.icaoCode)
    },
    arrivingFlights: function () {
      return this.flights.filter(
        (f) => f.destination == this.$route.params.icaoCode
      )
    },
    nextArrival: function () {
      let filtered = this.arrivingFlights.filter(
        (i) => i.estimatedArrivalTime > Date.now()
      )
      if (!filtered || filtered.length === 0) return []
      return filtered.reduce(function (prev, curr) {
        return prev.estimatedArrivalTime <= curr.estimatedArrivalTime
          ? prev
          : curr
      })
    },
    nextDeparture: function () {
      let filtered = this.departingFlights.filter((i) => i.eobt > Date.now())
      if (!filtered || filtered.length === 0) return []
      return filtered.reduce(function (prev, curr) {
        return prev.eobt <= curr.eobt ? prev : curr
      })
    },
  },
  created() {
    this.$store.dispatch('stations/getFlights', {
      icaoCode: this.$route.params.icaoCode,
    })
  },
  methods: {
    rowClassesDepartures(item) {
      let result = this.rowClasses(item)
      if (item === this.nextDeparture) {
        result.push('next')
      }
      return result
    },
    rowClassesArrivals(item) {
      let result = this.rowClasses(item)
      if (item === this.nextArrival) {
        result.push('next')
      }
      return result
    },
    rowClasses(item) {
      let result = []

      if (item.lastEvent == 'CNL' || item.state == 1) {
        result.push('cancelled')
      }
      if (item.origin == item.destination) {
        result.push('local')
      }
      return result
    },
    handleClick: function (row) {
      this.$router.push({
        name: 'Flight',
        params: { flightId: row.flightId },
      })
    },
    updateFlights: function (event) {
      let start = undefined
      let end = undefined
      if (this.dates) {
        start = this.dates[0]
      }
      if (this.dates && this.dates.length > 1) {
        end = this.dates[1]
      }
      if (start > end) {
        const t = end
        end = start
        start = t
      }
      this.$store.dispatch('stations/getFlights', {
        icaoCode: this.$route.params.icaoCode,
        startDate: start,
        endDate: end,
      })
    },
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> .local {
  background-color: rgba(144, 238, 144, 0.342);
}
.row-pointer >>> .next .text-start {
  border-top: 5px solid red;
}
.row-pointer >>> .cancelled {
  background-color: rgba(238, 144, 144, 0.541);
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>