<template>
  <v-card>
    <v-card-title>{{ $route.params.icaoCode }}</v-card-title>
    <v-card-text>
      <station-details />
    </v-card-text>
  </v-card>
</template>
<script>
import StationDetails from '@/components/Station/StationDetails.vue'
export default {
  components: {
    StationDetails,
  },
}
</script>

<style></style>
