<template>
  <v-card>
    <v-card-title>{{ $route.params.aircraftId }}</v-card-title>
    <v-card-text>
      <flights-table />
    </v-card-text>
  </v-card>
</template>

<script>
import FlightsTable from '@/components/FlightsTable.vue'
export default {
  name: 'AircraftDetails',
  components: {
    FlightsTable,
  },
}
</script>

<style></style>
